<template>
  <div align="center" justify="center" class="pb-8">
    <router-link to="/">
      <v-img  src='/img/logo-mobile-login.svg' />
    </router-link>
    <form @submit.prevent="onLogin" enctype="multipart/form-data">
      <v-flex class="pt-3 px-6"  align="center">
        <div class="login-text text-justify pt-8 pb-2">
          <v-icon color="#CB9865">mdi-account</v-icon>
          USUARIO:
        </div>
        <v-text-field
          :color=" this.$store.getters.get_errores ? 'red lighten-1' : '#CB9865' "
          :background-color=" this.$store.getters.get_errores ? 'red lighten-4' : 'white' "
          outlined
          v-model="credentials.name"
          name="user.name"
        ></v-text-field>
        <div class="login-text text-justify pb-2">
          <v-icon color="#CB9865">mdi-lock</v-icon>
          CONTRASEÑA:
        </div>
        <v-text-field
          :color=" this.$store.getters.get_errores ? 'red lighten-1' : '#CB9865' "
          :background-color=" this.$store.getters.get_errores ? 'red lighten-4' : 'white' "
          :append-icon="showPassw ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassw ? 'text' : 'password'"
          @click:append="showPassw = !showPassw"
          outlined
          v-model="credentials.password"
          name="user.password"
        ></v-text-field>
        <div v-show="get_error_login"> 
          <v-alert
            text
            dense
            type="error"
            border="left"
            class="Poppins-Regular my-2 py-3"
          >
            Usuario o contraseña incorrectos
          </v-alert>
        </div>

        <v-btn
        :disabled="this.$store.getters.get_btn_login"
        type="submit"
        class="mt-3 pa-5 login-text mr-2"
        x-large
        elevation="0"
        color="#CB9865"
        >
          Ingresar
        </v-btn>
      </v-flex>
    </form>
    <v-overlay :value="this.$store.getters.get_btn_login">
      <v-progress-circular indeterminate size="60"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "LoginMobile",

  data() {
    return {
      showPassw: false,
      credentials: {
        name: null,
        password: null,
      },
    };
  },

  computed: {
    ...mapGetters([
      "get_error_login",
      "get_logueado",
    ]),
  },

  watch: {
    get_logueado(value) {
      if (value) this.$router.go(0);
    },
  },

  methods: {
    ...mapActions({
      login: "login",
      verificar: "verificar",
    }),

    onLogin() {
      (this.loading = true), this.login(this.credentials);
    },
  },

};
</script>
<style>
.size{
  font-size: 17px !important;
  color: #000000;
}

.titulo{
  font-size: 21px;
  color: #000000;
}
</style>