<template>
  <div :class="{'px-6': $vuetify.breakpoint.smAndDown, 'px-16': $vuetify.breakpoint.mdAndUp}">
    <title-section  :class="{'pt-4': $vuetify.breakpoint.smAndDown, 'pt-8': $vuetify.breakpoint.mdAndUp}" textTitle="ÁREAS DE PRÁCTICA" />
    <div class="mt-8 mb-16 round-card">
        <v-card flat color="#F3F7F8" class="pb-4">
          <v-tabs
            v-model="tabs"
            color="#CB9865"
            background-color="#F3F7F8"
            
            centered
            show-arrows
            center-active
          >
            <v-tab
              v-for="n in derechos"
              :key="n"
              class="Poppins-Medium grey--text text--darken-4"
            >
              DERECHO {{ n }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabs" class="mx-12 mt-2 mb-6">
            <v-tab-item>
              <v-card flat>
                <v-card-title class="titleDerecho">
                  ¿Qué es el derecho empresarial?
                </v-card-title>
                <v-card-text class="descriptionDerecho">
                  El derecho comercial es la rama del derecho que regula y estudia la
                  actividad comercial de las empresas. Para ello definiremos a la empresa
                  como la actividad económicamente organizada en donde se produce la
                  unión del trabajo, administración y el capital, dedicados a satisfacer una
                  necesidad en el mercado.
                </v-card-text>
                <v-card-title class="titleDerecho">
                  ¿Por qué es importante un especialista en derecho empresarial?
                </v-card-title>
                <v-card-text class="descriptionDerecho">
                  Es importante tener en cuenta, a la hora de desarrollar una actividad
                  empresarial cuales son los puntos centrales, como la negociación, los
                  contratos empresariales, las garantías, arbitraje comercial, el comercio
                  electrónico, las responsabilidades de la empresa y los organismos
                  supervisores.
                  El derecho corporativo estudia y regula las empresas de gran envergadura
                  por lo que también se encuentra comprendido dentro del derecho empresarial abarcando el sector de las grandes empresas o corporaciones,
                  además involucra otras áreas como el derecho bursátil.
                  Las normas empresariales son abundantes por ello las empresas necesitan
                  de especialistas cuyo rol es importante en la formación y crecimiento de las
                  empresas. Las y los abogadossomos necesarios para desarrollar habilidades
                  y conocimientos que preparan a las sociedades y organizaciones para hacer
                  frente al panorama legal. La <strong>asesoría jurídica</strong> es el mejor recurso para
                  prevenir y reducir los riesgos o problemas potenciales, que se encuentran
                  asociados a la actividad comercial y además poder capitalizar las
                  oportunidades del mercado.
                </v-card-text>
                <v-divider color="#CB9865" />
                <v-card-title class="titleDerecho">
                  DERECHO DE EMPRESAS, COMERCIAL Y COORPORATIVO
                </v-card-title>
                <v-card-text class="descriptionDerecho">
                  • Asesoramiento integral y planificación legal empresaria
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  • Asistencia y análisis en la preparación de programas de gobierno
                  corporativo, compliance, responsabilidad, ética corporativa,
                  protección de datos.
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  • Due Diligence, contratos
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title class="titleDerecho">
                  ¿Qué es el derecho civil?
                </v-card-title>
                <v-card-text class="descriptionDerecho">
                  El derecho civil es la rama del derecho dedicada al estudio y regulación de
                  los distintos aspectos de la vida de las personas, es decir las relaciones de
                  familia (matrimonio, responsabilidad parental, etc.), sucesiones(herencia),
                  derechos reales (propiedad, usucapión) derechos y obligaciones que surgen
                  de las relaciones contractuales y sus formas de reparación (daños y
                  perjuicios, lucro cesante y daño emergente).
                </v-card-text>
                <v-card-title class="titleDerecho">
                  - Derecho de familia y sucesiones
                </v-card-title>
                <v-card-text class="descriptionDerecho">
                  Encargado de regular las relaciones que surgen del matrimonio, de la
                  descendencia como la responsabilidad parental, la conformación de la
                  familia y sus normas de parentesco y las leyes que regulan la herencia
                  como en el caso de las sucesiones.
                </v-card-text>
                <v-card-title class="titleDerecho">
                  - Derechos reales
                </v-card-title>
                <v-card-text class="descriptionDerecho">
                  En los derechos reales se regula la propiedad, la posesión, el uso y goce,
                  el usufruto, fideicomiso inmobiliario, y todas las leyes que rigen en
                  cuanto a los bienes inmuebles, así como la tenencia y adquisición de los
                  mismos.
                </v-card-text>
                <v-card-title class="titleDerecho">
                  - Derechos de obligaciones y contratos
                </v-card-title>
                <v-card-text class="descriptionDerecho">
                  Regula los actos y hechos jurídicos, sus consecuencias y efectos
                  vinculantes, como así también las normas que surgen en el régimen de
                  las obligaciones que nacen de las transacciones a las cuales se someten
                  las personas de manera voluntaria o a través de las acciones que
                  realizan.
                </v-card-text>
                <v-card-title class="titleDerecho">
                  - Derecho de daños
                </v-card-title>
                <v-card-text class="descriptionDerecho">
                  El derecho de daños o responsabilidad civil o bien aquellos casos que se
                  encuentran al margen de un contrato denominados responsabilidad civil extracontractual, como actualmente se lo denomina, aborda el problema
                  de los daños que tienen su origen en acciones y omisiones dolosas o
                  culposas, y además aquellos que son consecuencias de actividades que
                  generan un riesgo (ej. accidentes de tránsito, entre otros.) de los cuales
                  acarrea una reparación.
                </v-card-text>
                <v-divider color="#CB9865" />
                <v-card-title class="titleDerecho">
                  DERECHO CIVIL
                </v-card-title>
                <v-card-text class="descriptionDerecho">
                  • Redacción de contratos y documentos
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  • Representación y litigación judicial
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  • Derecho de familia y sucesiones
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  • Ejecución de títulos de crédito
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  • Responsabilidad civil por daños y perjuicios
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  • Accidentes de tránsito
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text class="descriptionDerecho">
                  En Argentina la legislación laboral, partiendo de la Constitución Nacional,
                  establecen una serie de derechos que protegen a los trabajadores, entre los
                  cuales se encuentran, el deber de garantizar las condiciones dignas y
                  equitativas de trabajo, el descanso y vacaciones pagas, derecho a cumplir
                  una jornada laboral limitada entre otras.
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  Ante un conflicto laboral en donde se atentan contra los derechos de los
                  trabajadores, es muy frecuente encontrarse que los mismos desconocen
                  sus derechos y resultan ser víctimas de actos injustos.
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  Es importante que quien se encuentra en situación de despido, maltrato o
                  acoso laboral, o detecte diferencias salariales que les corresponderían por
                  ley, trabajo en negro, accidentes o enfermedades profesionales, etc.,
                  concurra a un asesor legal que pueda guiar e informar sobre la realización
                  del reclamo correspondiente.
                </v-card-text>
                <v-divider color="#CB9865" />
                <v-card-title class="titleDerecho">
                  DERECHO LABORAL
                </v-card-title>
                <v-card-text class="descriptionDerecho">
                  • Asesoría jurídica, técnica e integral
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  • Despido injustificado
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  • Despido indirecto
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  • Accidentes de trabajo
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title class="titleDerecho">
                DERECHO PREVISIONAL (jubilaciones y pensiones)
                </v-card-title>
                <v-card-text class="descriptionDerecho">
                  Según lo dispone la ley el Sistema Integrado de Jubilaciones y Pensiones,
                  dentro del orbita pública será único, el (SIPA) como se lo denomina por sus
                  siglas, en donde el mismo se encuentra financiado a través de un sistema
                  solidario de reparto garantiza a los afiliados y beneficiarios con una
                  cobertura integra que viene establecida en la Constitución Nacional.
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  Los beneficios por la jubilación ordinaria, retiro por invalidez, pensión por
                  fallecimiento de aquellas provincias adheridas al sistema serán valorados
                  por el régimen de capitalización.
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  Es necesario el asesoramiento legal en jubilaciones y pensiones, debido a
                  que un especialista en el área, conoce todas las normas que regulan la
                  actividad. Por ello puede garantizar:
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  - La preparación de la documentación requerida y obtener el
                  reconocimiento de los servicios prestados, que se pueda actuar
                  conforme al procedimiento establecido, y además que se respeten
                  los plazos para cada trámite.<br>- Verificar la liquidación final, asesorar acerca de la posibilidad de
                  reajuste de haberes.<br>- Realizar la correcta representación legal en caso de conflicto o
                  diferencias en el procedimiento administrativo o liquidación final que
                  corresponda a cada afiliado o beneficiario.
                </v-card-text>
                <v-divider color="#CB9865" />
                <v-card-title class="titleDerecho">
                  DERECHO PREVISIONAL (jubilaciones y pensiones)
                </v-card-title>
                <v-card-text class="descriptionDerecho">
                  • Asesoramiento integral en jubilaciones, pensiones y reajuste de
                  haberes
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text class="descriptionDerecho">
                  La ley de defensa del consumidor o usuario, tiene por objeto la defensa de
                  quien adquiere o utiliza, en forma gratuita u onerosa, bienes o servicios
                  como destinatario final “consumidor”, sea persona física o jurídica.
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  A través de la ley se reconoce de pleno derecho la vulnerabilidad de las y
                  los consumidores en relaciones de consumo. La misma se presume cuando
                  se trata de personas humanas o jurídicas que se incorporen a contratos de
                  adhesión o a clausulas o condiciones predispuestas, por lo que de la
                  relación quedan expuestos a sus efectos o consecuencias.
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  <strong>Mencionaremos algunos casos que se pueden denunciar:</strong>
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  - Publicidad engañosa<br>- Información falsa o no adecuada<br>- Producto dañado o defectuoso<br>- Maltrato<br>- No reconocimiento de garantía posventa<br>- Aumento del precio del servicio sin aviso previo o cuando el mismo
                  no se cumplen en tiempo y forma.
                </v-card-text>
                <v-divider color="#CB9865" />
                <v-card-title class="titleDerecho">
                  DERECHO DEL CONSUMIDOR
                </v-card-title>
                <v-card-text class="descriptionDerecho">
                  • Asistencia y servicios jurídicos integrales en materia del derecho
                  al consumo, asesoramiento en controversias sobre eventuales
                  cláusulas abusivas, otorgando revisión y estudio de los contratos
                  de adhesión y representación ante futuros litigios.
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title class="titleDerecho">
                  ¿Qué es el derecho bancario?
                </v-card-title>
                <v-card-text class="descriptionDerecho">
                  El derecho Bancario es una rama del derecho comercial el cual se puede
                  conceptualizar como el conjunto de operaciones concerniente a las reglas
                  del banco, su importancia radica en que nos permite controlar que las
                  practicas bancarias se realicen de manera correctas y apegadas dentro del
                  marco legal, teniendo en cuenta que este derecho se actualiza de manera
                  constante, es importante el control de las normas de actuación de los
                  bancos.
                </v-card-text>
                <v-card-title class="titleDerecho">
                  ¿Por qué es importante un asesor legal en derecho bancario?
                </v-card-title>
                <v-card-text class="descriptionDerecho">
                  Un especialista en derecho bancario, puede asesorar correctamente para
                  llevar a cabo y con debida diligencia todo lo relativo a los distintos tramites
                  bancarios y negociaciones con la otra parte.
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  En la actualidad los contratos bancarios se han masificado, por lo cual gran
                  parte los negocios bancarios se realizan por medio de operaciones que
                  contienen contratos de adhesión con clausulas predispuestas, que limitan
                  la autonomía de la voluntad por parte de los contratantes, ya sea por
                  normas impuestas por la autoridad de contralor o por reglas internas de
                  cada institución financiera.
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  Al presentarse situaciones irregulares y no muy claras a la hora de firmar un
                  contrato puede ser perjudicial para las finanzas de los usuarios y es ahí
                  donde radica la importancia de un asesor legal en derecho bancario.
                </v-card-text>
                <v-divider color="#CB9865" />
                <v-card-title class="titleDerecho">
                  DERECHO BANCARIO
                </v-card-title>
                <v-card-text class="descriptionDerecho">
                  • Asesoramiento sobre servicios de operatorias y servicios
                  financieros en general, en base a las regulaciones del Banco Central.
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text class="descriptionDerecho">
                  El derecho de propiedad intelectual protege las creaciones fruto del
                  intelecto humano, brindándole exclusividad de uso a los creadores, de
                  obras científicas, literarias, artísticas o didácticas.
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  Su protección le permite exponerla o reproducirla, explotarla
                  comercialmente o autorizar a otros a hacerlo a través del patentamiento.
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  La propiedad intelectual se divide en dos ramas, el derecho de autor
                  vinculado con las creaciones artísticas como la música, literatura y la
                  propiedad intelectual como marcas, diseños industriales, patentes etc.
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  <strong>Las obras cubiertas por el derecho de propiedad intelectual:</strong>
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  Mencionaremos algunas de ellas;
                </v-card-text>
                <v-card-text class="descriptionDerecho">
                  - Libros o escritos<br>- Pinturas, fotografías<br>- Programas de computación<br>- Bases de datos<br>- Planos, maquetas, mapas<br>- Composiciones musicales y grabaciones<br>- Logos, marcas
                </v-card-text>
                <v-divider color="#CB9865" />
                <v-card-title class="titleDerecho">
                  DERECHO INTELECTUAL
                </v-card-title>
                <v-card-text class="descriptionDerecho">
                  • Asistencia y asesoramiento en derechos de autor, marcas, patentes, nombre de dominio y registros
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
import titleSection from '@/components/TitleSection.vue'
export default {
  name: "Areas-de-Practica",

  components: {
    titleSection,
  },

  data: () => ({
    tabs: null,
    derechos: [
      'DE EMPRESAS, COMERCIAL Y CORPORATIVO',
      'CIVIL',
      'LABORAL',
      'PREVISIONAL',
      'DEL CONSUMIDOR',
      'BANCARIO',
      'INTELECTUAL',
    ],
    show: false,
  }),
};
</script>

<style>
  .titleDerecho {
    font-size: 18px !important;
    font-family: "Poppins-Medium";
    background-color: #F3F7F8;
  }

  .descriptionDerecho {
    font-size: 16px !important;
    font-family: "Poppins-Regular";
    text-align: justify;
    background-color: #F3F7F8;
  }

  .round-card {
    border-radius: 25px;
    background-color: #F3F7F8;
  }
</style>