<template>
  <div class="Poppins-SemiBold titleSection text-justify py-4">
    {{ textTitle }}
  </div>
</template>

<script>
export default {
  name: 'TitleSection',
  props: {
    textTitle: String,
  }
}
</script>
<style>
  .titleSection {
    font-size: 1.7rem !important;
    color: #151316;
  }
</style>