<template>
  <div class="sizeT Poppins-SemiBold pb-2">
    {{ texto }}
  </div>
</template>

<script>
export default {
  name: 'LabelInput',
  props: {
    texto: String,
  }
}
</script>

<style>
  .sizeT{
    font-size: 20px !important;
    color: #000000;
  }
</style>