<template>
  <div class="my-16 px-4 background-color-about-me round">
    <v-card
      class="pa-8"
      flat
      elevation="0"
      align="start"
      color="transparent"
    >
      <div class="Poppins-SemiBold titleSection text-center py-6"> BLOG </div>
      <v-row no-gutters>
        <v-col
          v-for="(item, i) in (get_todas_noticias_bis)"
          :key="i"
          cols="12" md="4" sm="6" xs="12" class="py-4 px-4"
        >
          <v-card
            color="#E9EDEE"
            max-height="500"
            min-height="180"
            class=" overflow-hidden"
          >
            <!-- <img
              :src="'https://apirest.avalosestudio.com.ar/storage/imagenes/' + link"
              class="responsive-img"
            /> -->

            <div class="pa-2">
              <v-card-title class="titleNews"> {{item.titulo}} </v-card-title>

              <v-card-actions>
                <v-btn
                  rounded
                  color="#cb9865"
                  outlined
                  class="Poppins-SemiBold mt-4 mb-2 ml-2 pa-3"
                  @click="idSelectedNew(item)"
                >
                  Leer más
                  <v-icon class="pl-2">
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  export default {
    name: 'Blog-Section',
    //data() { 
    //  return {
    //    link: this.item.imagen
    //  }
    //},

    computed: mapGetters(['get_todas_noticias_bis']),

    mounted() {
      this.indexBisBlog();
    },

    methods: {
      ...mapActions(['indexBisBlog', 'selectedNew']),

      idSelectedNew (item) {
        let idNewSelected = item.id
        this.selectedNew(idNewSelected)
      },
    },
  }
</script>
<style>
  @font-face {
    font-family: Poppins-SemiBold;
    src: url('@~/public/fonts/Poppins-SemiBold.ttf');
  }

  .Poppins-SemiBold{
    font-family: "Poppins-SemiBold";
  }

  .titleNews{
    font-size: 19px;
    color: #151316;
    text-align: start;
    font-family: "Poppins-Medium";
    overflow-wrap: break-word;  
    word-wrap: break-word; 
    word-break: break-word;
  }

  .copeteNews{
    font-size: 18px;
    color: #151316;
    text-align: justify;
    font-family: "Poppins-Medium";
  }

  .cuerpoNews{
    font-size: 17px;
    text-align: justify;
    color: #151316;
    font-family: "Poppins-Regular";
  }
</style>