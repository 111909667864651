<template>
  <v-card
    color="#F3F7F8"
    class="pa-5"
    round
    flat
  >
    <v-row align="center" justify="center">
      <v-col cols="4">
        <v-img :src="img" class="responsive-img"/>
      </v-col>
      <v-col cols="8">
        <div class="Poppins-SemiBold sizeTitle"> {{text}} </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: 'CardAreaPractica',
  props: {
    img: String,
    text: String,
  },
}
</script>

<style>
@font-face {
  font-family: Poppins-SemiBold;
  src: url('@~/public/fonts/Poppins-SemiBold.ttf');
}
.Poppins-SemiBold{
  font-family: "Poppins-SemiBold";
}

.sizeTitle{
  font-size: 15px;
  color: #151316;
  text-align: center;
}

.sizeDescription{
  font-size: 12px;
  text-align: justify;
}

.responsive-img{
  max-width: 50px;
  height: auto;
}
</style>