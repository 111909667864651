<template>
  <div class="Poppins-SemiBold" v-if="$vuetify.breakpoint.smOnly || $vuetify.breakpoint.xsOnly" >
    <div class="pt-8" />
    <bottom-navigation-mobile/>
  </div>
</template>

<script>
import BottomNavigationMobile from '../BottomNavigationMobile.vue'
export default {
    components: { BottomNavigationMobile,},
}
</script>