<template>
  <div class="py-8 px-4 background-color-about-me round">
      <card-about-me img1="/img/analia-about-me.jpg" text="SOBRE MÍ"
      descriptionOne="Especialista en Asesoría Jurídica empresarial"
      descriptionTwo="Derecho comercial y negocios"
      descriptionThree="Propiedad intelectual"
      img2="/img/empresarial.svg"
      img3="/img/comercial.svg"
      img4="/img/intelectual.svg"></card-about-me>
  </div>
</template>

<script>
  import CardAboutMe from '@/components/Cards/CardAboutMe'

  export default {
    name: 'AboutMeSection',

    components: {
        CardAboutMe,
    },
  }
</script>

<style>
.background-color-about-me {
  background: #F3F7F8;
}

.round {
  border-radius: 25px;
}
</style>